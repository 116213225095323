import React from "react"
import styles from  './BestSeller.module.css'
import Jaggery from '../assects/Jaggery.png'
import Multiflora from '../assects/Multiflora.png'
import Moringa from '../assects/moronga.jpeg'
import ashbottle from '../assects/ash-bottle.png'
import HerbalTea from '../assects/HERBAL-TEA.png'
import { Container } from "react-bootstrap"


const BestSeller = () => {
    return(
        <div className={styles.BestSellerContainer} >
                <div className={styles.BestSellerHeading}>
                    <h2>Best Selling Organic Products</h2>
                </div>
                <div className={styles.bestSellerBox}>
                    <div className={styles.subBox}>
                        <img src={ashbottle} alt="ashwagandha" />
                        <button><a>Ashwagandha</a></button>
                    </div>

                    <div className={styles.subBox}>
                        <img src={Multiflora} alt="Honey" />
                        <button><a>Honey</a></button>
                    </div>

                    <div className={styles.subBox}>
                        <img src={HerbalTea} alt="Tea" />
                        <button><a>Hearbal Tea</a></button>
                    </div>

                    <div className={styles.subBox}>
                        <img src={Moringa} alt="Tea" />
                        <button><a>Moringa</a></button>
                    </div>

                    <div className={styles.subBox}>
                        <img src={Jaggery} alt="Tea" />
                        <button><a>Jaggery</a></button>
                    </div>
                </div>
            </div>

    )
}


export default BestSeller;

// import React from "react";
// import './BestSeller.css';
// import Jaggery from '../assects/Jaggery.png';
// import Multiflora from '../assects/Multiflora.png';
// import Moringa from '../assects/moronga.jpeg';
// import ashbottle from '../assects/ash-bottle.png';
// import HerbalTea from '../assects/HERBAL-TEA.png';

// const BestSeller = () => {
//     return (
//         <div className='best-seller container'>
//             <h2 className="Best-title text-center my-4">Best Selling Organic Products</h2>
//             <div className="row">
//                 <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
//                     <div className="sub-box text-center">
//                         <img src={ashbottle} alt="ashwagandha" className="img-fluid" />
//                         <button className="btn btn-outline-success mt-3"><a href="#">Ashwagandha</a></button>
//                     </div>
//                 </div>
//                 <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
//                     <div className="sub-box text-center">
//                         <img src={Multiflora} alt="Honey" className="img-fluid" />
//                         <button className="btn btn-outline-success mt-3"><a href="#">Honey</a></button>
//                     </div>
//                 </div>
//                 <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
//                     <div className="sub-box text-center">
//                         <img src={HerbalTea} alt="Herbal Tea" className="img-fluid" />
//                         <button className="btn btn-outline-success mt-3"><a href="#">Herbal Tea</a></button>
//                     </div>
//                 </div>
//                 <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
//                     <div className="sub-box text-center">
//                         <img src={Moringa} alt="Moringa" className="img-fluid" />
//                         <button className="btn btn-outline-success mt-3"><a href="#">Moringa</a></button>
//                     </div>
//                 </div>
//                 <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
//                     <div className="sub-box text-center">
//                         <img src={Jaggery} alt="Jaggery" className="img-fluid" />
//                         <button className="btn btn-outline-success mt-3"><a href="#">Jaggery</a></button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default BestSeller;
