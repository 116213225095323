import React from "react";
import './contact.css';
import index from '../assects/indexlogo.jpeg'
import { FaFacebook, FaInstagram, FaPinterest, FaTwitter, FaLinkedin, } from 'react-icons/fa';
const Contact = () => {
    return (
        <div id="contactus">
            <div className="contactbox">
                <div className="contactus"><h2>CONTACT US</h2>
                    <div className="line"></div>
                    <div className="left-contant">Get in touch:<br />
                        phone: 91 9893475508<br />
                        Email : info@forestwellness.in<br />
                    </div>
                    <div className="right-contant">
                        Bussines Address<br />
                        Index Enterprises<br />
                        M 78-79 New Siyaganj Mal Godam Road, opp. to Chimney, Indore, Madhya Pradesh 452007
                    </div>
                </div>
                <div className="follo-logo">
                <div className="indexlogo">
                        <div className='brandlogo'>
                            <div className="brand">
                                <h2 className="follow-us">A Brand Name By Index Enterprises</h2>
                                <div className="line"></div>
                            </div>
                            <img src={index} alt="COMING SOON" />
                        </div>
                    </div>
                    <div className="follow-us"><h2>FOLLOW US</h2>
                        <div className="line"></div>
                        <div className="icon"><FaFacebook><a href="https://www.facebook.com/profile.php?id=61557690291575" target="_blank"></a></FaFacebook></div>
                        <div className="icon"><FaInstagram><a href="https://www.instagram.com/_forest_wellness_/"></a></FaInstagram></div>
                        {/* <div className="icon"><FaTwitch /></div> */}
                        {/* <div className="icon"><a href="#"></a><FaTwitter /></div>  */}
                        <div className="icon"><a href="https://www.linkedin.com/in/atharva-mahajan-903996303/"></a><FaLinkedin /></div>
                        <div className="icon"><FaPinterest /></div>
                        {/* <div className="icon"><FaYoutube /></div> */}
                    </div>
                    
                </div>
            </div>

        </div >
    );
};

export default Contact