import React from 'react';
import styles from './BulkProducts.module.css';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import ashwagandha1 from '../assects/Ashwagandha R.jpeg'
import ashwagandha2 from '../assects/ashwagandha powder2.jpeg'
import Bulkpic from '../assects/Bulk-top.jpg'

import ashwagandha3 from '../assects/Ashwaganha E.jpeg'

import Moringa1 from '../assects/Moringa L.jpeg'
import Moringa2 from '../assects/Moringa P.jpeg'
import Moringa3 from '../assects/Moringa E.jpeg'


import steviya1 from '../assects/Stevia E.jpeg'
import steviya2 from '../assects/Stevia P.jpeg'
import steviya3 from '../assects/Stevia E2.jpeg'

import Neemleaves from '../assects/Neam-leaves.png'
import NeemBark from '../assects/Neam Bark.jpeg'
import NeemFruit from '../assects/Neam fruit.jpg'

import DryKarela from '../assects/dry karela.jpeg'

import Baboolbark from '../assects/babul-bark-powder-acacia-arabica-bark-powder-kikar-powder.jpg'
import BaboolFruit from '../assects/Babool Fruit.jpg'
import BaboolGum from '../assects/Babool Gum.jpg'

import rosemary from '../assects/rosemary.jpg'

import Tarmaric from '../assects/tarmaric.png.avif'

import TulsiLeaves from '../assects/Tulsi.jpeg.webp'
import TulsiTrees from '../assects/Tulsi Trees .jpg'

import Boswellia from '../assects/boswellia_serrata_immagine_pianta_2.jpg'
import BoswelliaGum from '../assects/Bsowellia gum .jpg.webp'

import wildamla from '../assects/Wild Amla.webp'

import Jamun from '../assects/Jamun.jpeg'

import Harada from '../assects/Harda.jpg'

import Nerali from '../assects/Syzygium-cumini-2.jpg'

import KaliMusli from '../assects/Kalimusli.jpeg'

import Niger from '../assects/Niger-seed.jpg.webp'

import PepperLong from '../assects/Long_Pepper.jpg.webp'
import PippaliLeaves from '../assects/Pippali-leaves.jpg'

import RamaTulsi from '../assects/Rama Tulsi.jpeg.webp'

const BulkProducts = () => {

    const [isHovered, setIsHovered] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <Helmet>
                <title>Forest Wellness | Forest Wellness</title>
                <meta name="description" content="Shop high-quality organic honey at Forest Wellness. Pure, natural, and sustainably sourced honey for your health and well-being." />
                <link rel="canonical" href="https://www.forestwellness.com/organic-honey" />
                <script type="application/ld+json">
                    {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "Organic Honey",
              "image": "https://www.forestwellness.com/path/to/organic-honey.jpg",
              "description": "Pure, natural, and sustainably sourced organic honey.",
              "brand": {
                "@type": "Brand",
                "name": "Forest Wellness"
              }
            }
          `}
                </script>
            </Helmet>

            <div id={styles.ourProducts}>
                <div className={styles.products}>
                    <div className={styles.productsSlider}><img src={Bulkpic} alt='This is the Image that tells about our products'></img></div>
                    <div className={styles.header}>
                        <h1>OUR PRODUCTS</h1>
                    </div>
                    <div className={styles.productsContainer}>
                        <div className={styles.productrow}>
                            <h2>ASHWAGANDHA-“Indian Winter cherry”</h2>
                            <div className={styles.para}>
                                <p>Ashwagandha (Withania somnifera) is commonly known as “Indian Winter cherry” or
                                    “Indian Ginseng”. It is one of the most important herb of Ayurveda. It is an evergreen
                                    shrub that grows in India, the Middle East, and parts of Africa. Its roots and orange-red
                                    fruit have been used for hundreds of years for medicinal purposes.</p>
                            </div>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div>
                                    <div className={styles.imgContainer}>
                                        <div className="">
                                            <div>
                                                <img className={styles.ashwagandha1Img} src={ashwagandha1} alt="this is img 1"></img>
                                            </div>
                                            <div className={styles.overlay}
                                                onMouseEnter={() => setIsHovered(true)}
                                                onMouseLeave={() => setIsHovered(false)}>
                                                <div className={styles.hoverReadmore}><a>
                                                    <button className={styles.readMore}>Read More</button></a></div>
                                                <div className={styles.readmoreContent}>
                                                    organic Ashwagandha Roots(Dried)<br />
                                                    Common Name : Ashwagandha Roots<br />
                                                    Botnical Name : Withania somnifera<br />
                                                    Family : solanaceae<br />
                                                    Plant parts used : Roots <br />
                                                    Products offering : 100% Pure organic<br />
                                                    Organic Certifications : NPOP, USDA(NOP)<br />
                                                    Country of Origin : India<br />
                                                    Quality : A Grade (Thick Roots), Maximum Potency<br />
                                                    Grade standards : Food Grade<br />
                                                    color & Appearance : Creamy White Color<br />
                                                    Packing size : 10/20/25<br />
                                                    MOQ : 1 MT<br />
                                                    Product Application : Food, Pharmaceutical, and Cosmatics<br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Ashwagandha Roots</div>
                                    {/* <div className={styles.hoverText}>Text on hover</div> */}
                                </div>
                                </div>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={ashwagandha2} alt=""></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                Organic Ashwagandha Roots Powder :<br />
                                                Products offering:<br />
                                                Organic Certifications :<br />
                                                Country of Origin : <br />
                                                Ouality : <br />
                                                Grade Standards :<br />
                                                Color & Appearane :<br />
                                                Mesh Size :<br />
                                                Shelf Life :<br />
                                                Packing Size :<br />
                                                MOQ : <br />
                                                Product Application : <br />

                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Ashwagandha Powder</div>
                                    {/* <div className="addcard-btn">Read more</div> */}
                                </div>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={ashwagandha3} alt=""></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Organic Ashwagandha Roots Powder :<br />
                                                    Products offering:<br />
                                                    Organic Certifications :<br />
                                                    Country of Origin : <br />
                                                    Ouality : <br />
                                                    Grade Standards :<br />
                                                    Color & Appearane :<br />
                                                    Mesh Size :<br />
                                                    Shelf Life :<br />
                                                    Packing Size :<br />
                                                    MOQ : <br />
                                                    Product Application : <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Ashwagandha Extract</div>
                                    {/* <div className="addcard-btn">Read More</div> */}
                                </div>
                            </div>
                        </div>

                        {/* ----------------------------- Moringa-Miracle Tree ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>MORINGA-Miracle Tree’</h2>
                            <p className={styles.para}>Moringa oleifera tree is also known as the ‘miracle tree’.
                                It is also known as the ‘drumstick tree’, ‘horseradish tree’ or ‘ben oil tree’,
                                found mostly in Asia, Africa, and South America. Almost all parts of the tree
                                are eaten or used as ingredients in traditional herbal medicines.</p>
                            <div className={styles.imgWrap}>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}> <img src={Moringa1} alt=""></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Organic Moringa Leaves (Dried)<br />
                                                    Common Name : Moringa Leaves<br />
                                                    Botnical Name : Moringa Oleifera<br />
                                                    Family : Moringaceae<br />
                                                    Plant parts used : Leaf <br />
                                                    Products offering : 100% Pure organic Moringa Leaves (Dried)<br />
                                                    Organic Certifications : NPOP , USDA(NOP)<br />
                                                    Country of Origin : India<br />
                                                    Quality : A Grade (Mesh Size), Maximum Potency<br />
                                                    Grade standards : Food Grade<br />
                                                    color & Appearance : Green colored<br />
                                                    MOQ : 1 MT<br />
                                                    Product Application : Food , Pharmaceutical , and Cosmatics<br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <img className='alt-img' src="" alt="alternate image "></img> */}


                                    <div class={styles.productTitle}>Moringa Leaves</div>
                                    {/* <div className="addcard-btn"> read more</div> */}
                                </div>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>  <img src={Moringa2} alt=""></img>

                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    organic Moringa Leaf Extract:<br />
                                                    Products offering:<br />
                                                    Organic Certifications :<br />
                                                    Country of Origin : <br />
                                                    Ouality : <br />
                                                    Grade Standards :<br />
                                                    Color & Appearane :<br />
                                                    Mesh Size :<br />
                                                    Shelf Life :<br />
                                                    Packing Size :<br />
                                                    MOQ : <br />
                                                    Product Application : <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Moringa Powder</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>  <img src={Moringa3} alt=""></img>

                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    organic Moringa Leaf Extract:<br />
                                                    Products offering:<br />
                                                    Organic Certifications :<br />
                                                    Country of Origin : <br />
                                                    Ouality : <br />
                                                    Grade Standards :<br />
                                                    Color & Appearane :<br />
                                                    Mesh Size :<br />
                                                    Shelf Life :<br />
                                                    Packing Size :<br />
                                                    MOQ : <br />
                                                    Product Application : <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    <div class={styles.productTitle}>Moringa Extract</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                            </div>
                        </div>

                        {/* ----------------------------- Stevia-Rebaudiana Bertoni ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>STEVIA-Stevia Rebaudiana Bertoni</h2>
                            <p className={styles.para}>Stevia, also known as Stevia rebaudiana Bertoni, is a bushy
                                shrub that is part of the sunflower family. There are 150 species of stevia. Stevia
                                also known as Candy leaves, Sweet Honey Leaf, and Sugar Leaf, which belong to the family Asteraceae.
                                Stevioside and rebaudioside are the major active components present in these leaves.  Stevia is a sugar
                                substitute made from the leaves of the Stevia rebaudiana plant. The leaves contain chemicals called
                                steviol glycosides that have a highly concentrated sweet flavor, is about 200 to 400 times sweeter than
                                table sugar. It is used as a non-nutritive sweetener and herbal supplement.</p>
                            <div className={styles.imgWrap}>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={steviya1} alt="" />
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Organic Stevia Leaves (Dried)<br />
                                                    Common Name : Stevia Leaves<br />
                                                    Botnical Name : Stevia Rebaudiana<br />
                                                    Plant parts used : Leaf <br />
                                                    Products offering : 100% Pure organic Moringa Leaves (Dried)<br />
                                                    Organic Certifications : NPOP , USDA(NOP)<br />
                                                    Country of Origin : India<br />
                                                    Quality : A Grade ,Maximum Potency<br />
                                                    Grade standards : Food Grade<br />
                                                    color & Appearance : Green colored<br />
                                                    Shelf Life : <br />
                                                    MOQ : 1 MT<br />
                                                    Product Application : Food , Pharmaceutical , and Cosmatics<br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Stevia Leaves</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={steviya2} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Organic Stevia Leaf Powder :<br />
                                                Products offering:<br />
                                                Organic Certifications :<br />
                                                Country of Origin : <br />
                                                Ouality : <br />
                                                Grade Standards :<br />
                                                Color & Appearane :<br />
                                                Mesh Size :<br />
                                                Shelf Life :<br />
                                                Packing Size :<br />
                                                MOQ : <br />
                                                Product Application : <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Stevia Powder</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={steviya3} alt=""></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Organic Stevia Leaf Powder :<br />
                                                    Products offering:<br />
                                                    Organic Certifications :<br />
                                                    Country of Origin : <br />
                                                    Ouality : <br />
                                                    Grade Standards :<br />
                                                    Color & Appearane :<br />
                                                    Mesh Size :<br />
                                                    Shelf Life :<br />
                                                    Packing Size :<br />
                                                    MOQ : <br />
                                                    Product Application : <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Stevia Extract</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>

                            </div>
                        </div>

                        {/* ----------------------------- Neem-Azadirachta indica ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Neem-Azadirachta indica</h2>
                            <p className={styles.para}>Neem leaves, also known as "Azadirachta indica," are derived from the neem tree, which is native to the Indian subcontinent.
                                Neem is also commonly referred to as "Indian lilac".Neem leaves have potent antibacterial and antifungal properties, making them effective in
                                treating skin infections, acne, and other bacterial or fungal conditions.They are known for their anti-inflammatory effects, which help reduce
                                swelling and pain.Neem leaves are often used in Ayurvedic medicine for their detoxifying properties, helping to cleanse the blood and promote overall health.</p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={NeemBark} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Antimicrobial: Fights bacterial and fungal infections.<br />
                                                Oral Care: Used for dental hygiene and preventing tooth decay.<br />
                                                Digestive Health: Treats ulcers and indigestion.<br />
                                                Fever and Malaria: Used in traditional treatments for fever and malaria.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Neem Bark</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={Neemleaves} alt='this is an image of Neem leaf'></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Uses:<br />
                                                    <br />
                                                    Skin Care: Treats acne, eczema, and other skin conditions.<br />
                                                    Immune System: Boosts immunity and fights infections.<br />
                                                    Oral Health: Maintains dental hygiene and prevents gum disease.<br />
                                                    Detoxification: Purifies the blood and detoxifies the body.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Neem Leaves</div>
                                </div>
                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={NeemFruit} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Pest Control: Produces neem oil, a natural pesticide.<br />
                                                Skin and Hair Care: Treats dandruff and scalp conditions.<br />
                                                Medicine: Used to treat malaria, diabetes, and digestive issues.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Neem Fruit</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>


                            </div>
                        </div>

                        {/* ----------------------------- Dry Karela ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Dry Karela-Bitter gourd</h2>
                            <p className={styles.para}>Dry karela, also known as "bitter gourd" or "bitter melon," is a dried form of the karela vegetable,
                                scientifically known as Momordica charantia. In Hindi, it's called "karela," while in English, it's referred to as "bitter
                                gourd" or "bitter melon". Dry karela is well-known for its ability to help manage blood sugar levels, making it beneficial
                                for people with diabetes. It aids in detoxifying the liver and improving liver function. Dry karela can stimulate digestion and relieve constipation, promoting overall digestive health.</p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={DryKarela} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Preparation:<br />
                                                Karela is typically sliced and sun-dried or dehydrated to preserve it.<br />
                                                This process concentrates its bitter flavor and makes it shelf-stable for longer storage.                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Stevia Powder</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>


                            </div>
                        </div>

                        {/* ----------------------------- Babool Bark ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Babool Bark-Acacia nilotica</h2>
                            <p className={styles.para}> Babool bark, also known as "Acacia nilotica" or "Gum Arabic tree,"
                                is commonly referred to as "Kikar" or "Babul" in India. The tree is native to Africa,
                                the Middle East, and the Indian subcontinent, and its bark has been used for centuries
                                in traditional medicine and various other applications. Babool bark is known for its strong
                                antibacterial and antimicrobial properties, making it useful in treating infections and wounds.
                                It has astringent properties, which help in tightening tissues and are often used to treat
                                bleeding gums and other oral health issues.</p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={Baboolbark} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Medicinal Uses: It has antimicrobial and anti-inflammatory properties,<br />
                                                making it useful in treating skin conditions like eczema and wounds.<br />
                                                It’s also used in oral health to manage gum diseases and as a mouthwash.<br />
                                                Astringent: It can act as an astringent, helping to tighten tissues and reduce secretions.<br />
                                                Digestive Health: It's sometimes used in digestive disorders, such as diarrhea or dysentery, due to its astringent properties.                                                                                       </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Babool Bark</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>

                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={BaboolFruit} alt='this is an image of Neem leaf'></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Uses:<br />
                                                    <br />
                                                    Medicinal Uses: The fruit is often used in traditional medicine to treat various ailments,<br />
                                                    including digestive problems and respiratory issues.<br />
                                                    Nutritional Value: It has some nutritional value and can be used in herbal remedies or food preparations.<br />
                                                    Antioxidant Properties: The fruit may have antioxidant properties that contribute to overall health.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Babool Fruit</div>
                                </div>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={BaboolGum} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Gum Care: The gum is traditionally used to strengthen gums and reduce dental issues like gingivitis.<br />
                                                Digestive Health: It can be used as a laxative or to help with digestive issues due to its mucilaginous properties.<br />
                                                Culinary Uses: Sometimes used as a thickening agent in cooking, especially in traditional recipes.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Babool Gum</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>

                            </div>
                        </div>

                        {/* ----------------------------- Rose Mary-Rosmarinus officinalis ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Rose Mary-Rosmarinus officinalis</h2>
                            <p className={styles.para}> Rosemary, scientifically known as Rosmarinus officinalis, is a fragrant evergreen herb native to the Mediterranean region.
                                It is also commonly referred to as "Rosmarinus" or simply "Rosemary". Rosemary is rich in antioxidants and anti-inflammatory compounds,
                                which are thought to boost the immune system and improve blood circulation. Traditionally, rosemary has been associated with improving memory
                                and concentration. Some studies suggest that it may help in preventing cognitive decline. Rosemary is known to stimulate digestion and alleviate
                                indigestion, bloating, and other digestive discomforts.</p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={rosemary} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Cultural Significance:<br />
                                                <br />
                                                Symbolism: In various cultures, rosemary is a symbol of remembrance and is often used in weddings,<br />
                                                funerals, and other ceremonies.<br />
                                                Historical Use: Rosemary has a rich history and has been used in <br />rituals, medicine, and cuisine for thousands of years, dating back to <br />
                                                ancient Egypt, Greece, and Rome.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Rose Mary</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>

                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={rosemary} alt='this is an image of Neem leaf'></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Oral Health: Babool bark is commonly used in traditional oral care practices. <br />
                                                    It is often chewed directly  <br />
                                                    or used as an ingredient in toothpaste and mouthwashes to strengthen gums and teeth.  <br />
                                                    Skin Care: The bark is used in skincare to treat wounds, cuts, and skin irritations. <br />
                                                    It can be applied as a poultice or in powdered form mixed with water. <br />
                                                    Herbal Medicine: In Ayurveda and Unani medicine, babool bark is used to treat conditions  <br />
                                                    like diarrhea, dysentery, and diabetes due to its astringent and anti-diabetic properties.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Rose Mary</div>
                                </div>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={rosemary} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:
                                                Oral Health: Babool bark is commonly used in traditional oral care practices. It is often chewed directly or used as an ingredient in toothpaste and mouthwashes to strengthen gums and teeth.
                                                Skin Care: The bark is used in skincare to treat wounds, cuts, and skin irritations. It can be applied as a poultice or in powdered form mixed with water.
                                                Herbal Medicine: In Ayurveda and Unani medicine, babool bark is used to treat conditions like diarrhea, dysentery, and diabetes due to its astringent and anti-diabetic properties.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Rose Mary</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>

                            </div>
                        </div>


                        {/* ----------------------------- Tarmaric-curucuma longa ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Tarmaric-Curcuma longa</h2>
                            <p className={styles.para}>
                                Turmeric, scientifically known as Curcuma longa, is a bright yellow-orange spice commonly used in cooking, particularly in South Asian cuisine. It is also known by several other names, such as "Indian saffron" or "Haldi"
                                Turmeric contains curcumin, a compound with strong anti-inflammatory properties, making it useful in managing conditions like arthritis and other inflammatory disorders.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={Tarmaric} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Culinary Uses:<br />
                                                <br />
                                                Spice: Turmeric is widely used as a spice in a variety of dishes,<br />
                                                especially in curries, soups, and rice. It imparts a distinctive yellow color and earthy,<br />
                                                slightly bitter flavor.<br />
                                                Golden Milk: A traditional drink known as "golden milk,"<br /> made with turmeric, milk, and other spices, is consumed for its health benefits,<br />
                                                particularly as a remedy for colds and inflammation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Tarmaric</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>


                            </div>
                        </div>

                        {/* ----------------------------- Tulsi-Ocimum Sanctum ------------------------------------ */}

                        <div className={styles.productrow}>
                            <h2>Tulsi-Ocimum sanctum</h2>
                            <p className={styles.para}>
                                Tulsi, scientifically known as Ocimum sanctum or Ocimum tenuiflorum, is commonly referred to as "Holy Basil" in English. It is a sacred plant in Hinduism and is widely cultivated throughout India and Southeast Asia.
                                Tulsi is considered an adaptogen, meaning it helps the body adapt to stress and promotes mental balance.
                                Tulsi is known for its immune-boosting properties. It helps fight infections, reduce fever, and protect against various respiratory illnesses.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={TulsiLeaves} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Respiratory Health: Treats coughs, colds, asthma.<br />
                                                Immunity Booster: Strengthens immune system.<br />
                                                Stress Relief: Reduces stress, promotes mental balance.<br />
                                                Digestive Aid: Helps with indigestion and bloating.<br />
                                                Skin Care: Treats acne and skin conditions.<br />
                                                Culinary Uses: Used in teas and dishes for flavor and health.<br />
                                                Oral Health: Maintains oral hygiene, prevents infections.<br />
                                                Wound Healing: Promotes healing of cuts and insect bites.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Tulsi Leaves</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>

                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={TulsiTrees} alt='this is an image of Neem leaf'></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Uses:<br />
                                                    <br />
                                                    Spiritual Significance: Sacred in Hinduism; brings positive energy.<br />
                                                    Air Purification: Emits oxygen, absorbs harmful gases.<br />
                                                    Insect Repellent: Naturally repels insects, especially mosquitoes.
                                                    <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Tulsi Trees</div>
                                </div>

                            </div>
                        </div>


                       {/* ----------------------------- Harad(Terminallia Chebula) ------------------------------------ */}



                        <div className={styles.productrow}>
                            <h2>Boswellia Serrata</h2>
                            <p className={styles.para}>
                                The botanical name for Boswellia is Boswellia serrata. This tree is native to India, and its resin is commonly known as Indian frankincense. Boswellia serrata has been used for centuries in traditional medicine, particularly in Ayurvedic practices, for its anti-inflammatory and pain-relieving properties. The resin extracted from the tree is often used to treat conditions like arthritis, asthma, and inflammatory bowel diseases.                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={BoswelliaGum} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Adhesive: The gum is sometimes used as a natural adhesive in various industries.<br />
                                                Food Industry: It can be used as a stabilizer or emulsifier in certain food products.<br />
                                                Pharmaceutical Industry: Though less common than the resin,<br /> the gum may be used in the preparation of some medicinal formulations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Boswellia Serrata Gum</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>

                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={Boswellia} alt='this is an image of Neem leaf'></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Uses:<br />
                                                    <br />
                                                    Cosmetics: The resin is used in skincare products for its anti-inflammatory and anti-aging properties.<br />
                                                    Modern Medicine: Extracts of Boswellia serrata resin are used in the formulation of supplements <br />
                                                    and medications aimed at reducing inflammation, treating arthritis, and improving joint health.<br />
                                                    Aromatherapy: The resin is often burned as incense or used in essential oils for its calming <br />
                                                    and grounding effects. It is believed to enhance meditation and spiritual practices.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Boswellia Serrata Rasin</div>
                                </div>

                            </div>
                        </div>

                        {/* ----------------------------- Emblica Offinalis (Wild Amla) ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Emblica Offinalis (Wild Amla)</h2>
                            <p className={styles.para}>
                                Emblica officinalis, commonly known as wild amla or Indian gooseberry, is a small to medium-sized deciduous tree native to India and other parts of Southeast Asia.
                                The fruit of the wild amla tree is small, round, and greenish-yellow. It has a translucent, shiny surface and is known for its sour and slightly bitter taste.
                                The tree itself can grow up to 8-18 meters in height, with a crooked trunk and spreading branches. The leaves are small, simple, and light green, resembling pinnate leaves.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={wildamla} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Adhesive: The gum is sometimes used as a natural adhesive in various industries.<br />
                                                Food Industry: It can be used as a stabilizer or emulsifier in certain food products.<br />
                                                Pharmaceutical Industry: Though less common than the resin,<br /> the gum may be used in the preparation of some medicinal formulations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Emblica Offinalis, (Wild Amla)</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                            </div>
                        </div>

                        {/* ----------------------------- Eugenia Jambolana (Jamun) ------------------------------------ */}

                        <div className={styles.productrow}>
                            <h2>Eugenia Jambolana (Jamun)</h2>
                            <p className={styles.para}>
                                Eugenia jambolana, commonly known as Jamun or Java plum, is a tropical, evergreen tree native to the Indian subcontinent and Southeast Asia.
                                Jamun fruits are small, oblong, and dark purple to black when fully ripe. They have a sweet-tart taste with a slightly astringent aftertaste.
                                The Jamun tree is large, reaching up to 30 meters in height, with dense foliage and glossy green leaves.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={Jamun} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Diabetes Management: Jamun seeds are traditionally used in Ayurvedic medicine to manage blood sugar levels.<br />
                                                Fresh Consumption: Jamun is often eaten fresh or used in the preparation of juices, jams, and jellies.<br />
                                                Anti-inflammatory: Jamun possesses anti-inflammatory properties that can help reduce inflammation in the body.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Eugenia Jambolana, Extracy(Jamun)</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                            </div>
                        </div>


                        {/* ----------------------------- Harad(Terminallia Chebula) ------------------------------------ */}

                        <div className={styles.productrow}>
                            <h2>Harad (Terminallia Chebula)</h2>
                            <p className={styles.para}>
                                Harad, also known as Terminalia chebula, is a significant medicinal plant in Ayurveda, often referred to as the "king of medicines.
                                The fruit of the Harad tree is small, oval, and yellow to orange-brown in color when ripe. It has a wrinkled surface and a slightly bitter taste.
                                Harad is a medium to large deciduous tree, growing up to 30 meters in height, with spreading branches and greenish leaves.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={Harada} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Immunity Booster: Regular consumption of Harad is believed to strengthen the immune system and protect against infections. <br />
                                                Antioxidant: It is rich in antioxidants, which help combat free radicals and support overall health. <br />
                                                Anti-inflammatory: Harad has anti-inflammatory properties that are beneficial in treating inflammatory conditions. <br />
                                                Laxative: The fruit acts as a mild laxative, helping to relieve constipation. <br />
                                                Digestive Health: Harad is well-known for its digestive benefits. It is a key ingredient in Triphala, <br />
                                                a traditional Ayurvedic formulation used for improving digestion and detoxifying the body.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Harad, (Terminallia Chebula)</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                            </div>
                        </div>

                        {/* ----------------------------- Nerali (Syzygium Cumini) ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Nerali (Syzygium Cumini)</h2>
                            <p className={styles.para}>
                                Nerali, also known as Syzygium cumini or Jamun, is a fruit-bearing tree.
                                Rich in antioxidants and vitamin C, the fruit is consumed fresh, in juices, or as jams.
                                It's known for managing blood sugar levels, making it beneficial for diabetics.The seeds are powdered and used in traditional medicine to control diabetes, as they help regulate blood glucose levels.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={Nerali} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Fruit: Manages blood sugar; rich in antioxidants.<br />
                                                Seeds: Controls diabetes; regulates glucose levels.<br />
                                                Leaves: Aids digestion; promotes oral health.<br />
                                                Bark: Treats skin conditions; helps with diarrhea.<br />
                                                Wood: Used in furniture and tool making.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Nerali (Syzygium Cumini)</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                            </div>
                        </div>

                        {/* ----------------------------- Kali Musli (Curculigo Orchioides) ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Curculigo Orchioides (Kali Musli)</h2>
                            <p className={styles.para}>
                                Curculigo Orchioides, commonly known as Kali Musli, is a medicinal herb valued in Ayurveda and traditional medicine.<br />
                                Appearance: It’s a small, perennial herb with grass-like leaves and tuberous roots.   <br />
                                Grows in tropical and subtropical regions, especially in India.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={KaliMusli} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Aphrodisiac: Traditionally used to enhance sexual health and vitality.<br />
                                                Immunity Booster: Strengthens the immune system, helping to ward off infections.<br />
                                                Anti-inflammatory: Helps reduce inflammation and pain, particularly in joint disorders.<br />
                                                Reproductive Health: Supports reproductive health in both men and women.<br />
                                                General Tonic: Acts as a rejuvenating tonic, improving overall strength and stamina.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Curculigo Orchioides (Kali Musli)</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                            </div>
                        </div>




                        {/* ----------------------------- Niger Seeds ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Niger Seeds</h2>
                            <p className={styles.para}>
                                Niger seeds (Guizotia abyssinica) are small, oil-rich seeds commonly used in cooking, bird feed, and traditional medicine.<br />
                                Appearance: Small, black seeds similar in size to sesame seeds.<br />
                                Native to Ethiopia, but widely cultivated in India and other parts of Africa.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={Niger} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Cooking Oil: Niger seed oil is used for cooking and is rich in healthy fats.<br />
                                                Bird Feed: Popular as bird feed, particularly for wild birds like finches.<br />
                                                Nutritional Value: High in protein, healthy fats, and essential minerals like magnesium and calcium.<br />
                                                Traditional Medicine: Used to treat skin infections, improve digestive health, and boost the immune system.<br />
                                                Soil Fertility: The plant helps in soil conservation and is often grown as a cover crop.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Niger Seeds</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                            </div>
                        </div>

                        {/* ----------------------------- Pepper longs ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Pepper long</h2>
                            <p className={styles.para}>
                                Pepper Long (Piper longum), also known as Indian Long Pepper, is a spice and medicinal plant used in traditional medicine and culinary practices.<br />
                                Appearance: The fruit is long and cylindrical, resembling a small, thin cone. It is usually dried and used whole or powdered.<br />
                                Origin: Native to India and Southeast Asia.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={PepperLong} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Culinary Spice: Used in Indian and Southeast Asian cuisines for its pungent flavor,<br /> often in pickles, curries, and spice blends.<br />
                                                Digestive Aid: Traditionally used to treat digestive disorders like indigestion, gas, and loss of appetite.<br />
                                                Respiratory Health: Helps in managing respiratory conditions like asthma, bronchitis, and cough.<br />
                                                Immunity Booster: Enhances the immune system and is often included in herbal tonics.<br />
                                                Pain Relief: Used in traditional medicine to relieve pain and inflammation, especially in joint disorders.<br />
                                                Weight Management: Believed to boost metabolism and aid in weight management.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Pepper Long</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                                <div className={styles.productBox}>
                                    <div className={styles.imgContainer}>
                                        <img src={PippaliLeaves} alt='this is an image of Neem leaf'></img>
                                        <div className={styles.overlay}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}>
                                            <div className={styles.hoverReadmore}><a>
                                                <button className={styles.readMore}>Read More</button></a></div>
                                            <div className={styles.readmoreContent}>
                                                <p>
                                                    Uses:<br />
                                                    <br />
                                                    Respiratory Health: Used in treating respiratory issues like asthma, bronchitis, and cough.<br />
                                                    The leaves may be used in herbal teas or decoctions to help clear congestion and ease breathing.<br />
                                                    Digestive Aid: They can help stimulate digestion and are sometimes used to treat indigestion and bloating.<br />
                                                    Anti-inflammatory: The leaves have anti-inflammatory properties and can be used in poultices or pastes to reduce swelling and pain.<br />
                                                    Immune Support: Like the fruit, the leaves are believed to support the immune system <br />
                                                    and may be included in herbal formulations for boosting overall health.<br />
                                                    Fever Reduction: Traditionally, pippali leaves have been used to help reduce fever and alleviate symptoms of colds and flu.<br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={styles.productTitle}>Papali Leaves</div>
                                </div>
                            </div>
                        </div>

                        {/* ----------------------------- Rama Tulsi-Ocimum sanctum ------------------------------------ */}


                        <div className={styles.productrow}>
                            <h2>Rama Tulsi-Ocimum sanctum</h2>
                            <p className={styles.para}>
                                Rama Tulsi (Ocimum sanctum), also known as Holy Basil, is one of the most revered and commonly grown varieties of Tulsi in India. It holds significant importance in Ayurveda and traditional medicine.<br />
                                Appearance: Rama Tulsi has green leaves with a mild, sweet aroma. The plant grows as a small shrub with purplish or white flowers.
                                Considered sacred in Hinduism, often planted in courtyards and used in religious rituals.
                            </p>
                            <div className={styles.imgWrap}>

                                <div className={styles.productBox}><div className={styles.imgContainer}>
                                    <img src={RamaTulsi} alt=""></img>
                                    <div className={styles.overlay}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <div className={styles.hoverReadmore}><a>
                                            <button className={styles.readMore}>Read More</button></a></div>
                                        <div className={styles.readmoreContent}>
                                            <p>
                                                Uses:<br />
                                                <br />
                                                Respiratory Health: Rama Tulsi is widely used to treat respiratory issues like coughs, colds, asthma, and bronchitis. <br /> It's often included in herbal teas and remedies. <br />
                                                Immunity Booster: Regular consumption of Rama Tulsi helps strengthen the immune system and protects against common infections. <br />
                                                Stress Relief: Known for its adaptogenic properties, Rama Tulsi helps the body cope with stress and promotes mental balance. <br />
                                                Digestive Health: It aids in digestion, reduces bloating, and helps in treating indigestion and other gastrointestinal issues. <br />
                                                Anti-inflammatory and Antioxidant: Rama Tulsi has potent anti-inflammatory and antioxidant properties, making it effective in reducing inflammation and combating oxidative stress.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class={styles.productTitle}>Rama Tulsi</div>
                                    {/* <div className="addcard-btn">read more</div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default BulkProducts;